<template>
  <div
    class="mbom-impact-test px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <SideBar class="sm:w-1/5 sm:order-first sm:mt-0 w-full order-last mt-4" />
      <div class="sm:w-4/5 w-full">
        <TitleComponent
          class="py-8"
          title="Critical Height Test"
          size="large"
        />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent
              class="py-8"
              title="Critical Height Test Description"
              size="medium"
            />
            <a
              :href="
                require('../../assets/IPSeries/CriticalHeightDetails-min.png')
              "
              target="_blank"
            >
              <img
                style="width:75%;"
                class="m-auto"
                src="@/assets/IPSeries/CriticalHeightDetails-min.png"
              />
            </a>
            <div class="p-6">
              <TitleComponent class="py-8" title="Test Purpose" size="medium" />
              <p class="p-light">
                This test is used to determine the critical height at which a
                flame initiation transitions to an explosive reaction (explosion
                or detonation). This test is sometimes referred to as a critical
                mass test because the results are dependent on the
                self-confinement provided by the mass of material. In this test,
                a flame initiator (bag igniter) is placed at the bottom of a
                pipe assembly filled with the test material. Pipes of varying
                lengths and diameters are used to contain the test material. The
                test is performed by selecting a diameter, and progressively
                changing the height of the pipe until the material transitions
                from burning to explosion or detonation. The diameter is then
                changed and the progressive height variation testing is
                repeated. Normally, a curve can be fitted using the data, to
                predict the critical height for other diameters as well. A “+”
                reaction, for explosion, is one in which the pipe is damaged.
                The test is concluded at each diameter by running a minimum of
                three successive trials which produce a “-” result at a height
                below a level which produces a positive reaction (explosion).
                This level is referred to as the critical height at that
                diameter.
              </p>

              <p class="p-light mt-4">
                Example in-process applications where the goal is to operate
                under the critical height include hopper and bin designs, feed
                systems, etc.
              </p>

              <TitleComponent class="py-8" title="Example Plot" size="medium" />

              <a
                :href="
                  require('../../assets/IPSeries/CriticalHeightPlot-min.png')
                "
                target="_blank"
              >
                <img
                  style="width:75%;"
                  class="m-auto"
                  src="@/assets/IPSeries/CriticalHeightPlot-min.png"
                />
              </a>

              <TitleComponent
                class="py-8"
                title="Special Instructions"
                size="medium"
              />
              <h2 class="font-lg font-bold my-6">Critical Height Definition</h2>
              <p class="p-light">
                The critical height for a given diameter is the level that
                produces three negative results with at least one positive
                result (explosion) at the next higher increment at a given
                diameter.
              </p>
              <h2 class="font-lg font-bold my-6">Typical test methodology</h2>
              <p class="p-light">
                The fill height of the test material at a given diameter is
                progressively increased or decreased until the critical height
                is determined (see definition above). The diameter is then
                incremented and the critical height determined for that
                diameter. Generally, 1-inch, 2-inch, and 3-inch diameter pipes
                are tested in lengths ranging from 3 inches to 24 inches in
                3-inch increments (maximum).
              </p>
              <h2 class="font-lg font-bold my-6">Data extrapolation</h2>
              <p class="p-light">
                Plotting the critical height versus pipe diameter test data
                permits identification of data trends for regression and
                interpolation/ extrapolation. For example, data may be fit to
                the equation: CH = a + bD^0.25, where CH is the critical height
                at the diameter D. NOTE: Larger diameter test pipes (e.g. 6-in)
                or in-process simulation tests may be required for data
                extrapolation to in-process conditions.
              </p>
              <h2 class="font-lg font-bold my-6">
                Application to process equipment
              </h2>
              <p class="p-light">
                A plot of the critical height versus diameter can be used in
                equipment design and to assess the explosion potential of
                existing process equipment. For example, if the fill height of a
                substance in a steel cylindrical hopper exceeds its critical
                height for that diameter, it will likely explode if bottom
                ignited, producing blast overpressure and fragments that may
                result in the loss of life.
              </p>

              <TitleComponent
                class="py-8"
                title="Test Variations"
                size="medium"
              />
              <p class="p-light">
                Typically the test is completed in a steel pipe of differing
                diameters and heights; however, the test can be completed with
                various types of confining media:
              </p>

              <ul class="p-light list-disc list-inside mt-4">
                <li>
                  Container construction
                  <ul class="pl-6 list-disc list-inside">
                    <li>Thick-cased steel (high confinement)</li>
                    <li>Thin-cased steel (medium confinement)</li>
                    <li>Plastics, textiles, fiberboard (low confinement)</li>
                  </ul>
                </li>
                <li>
                  Surrounding medium
                  <ul class="pl-6 list-disc list-inside">
                    <li>Atmosphere</li>
                    <li>Sand (displaceable medium)</li>
                  </ul>
                </li>
                <li>
                  Supplemental venting
                  <ul class="pl-6 list-disc list-inside">
                    <li>External vent panels</li>
                    <li>Internal vent tubes</li>
                  </ul>
                </li>
              </ul>
              <p class="p-light mt-4">
                Other specifics such as the temperature, density, processing
                stage, etc. can be set by the specific in-process configuration.
                Below are some photos of examples of in-process specific
                critical height tests as well as example results with
                single-base propellant.
              </p>
              <h2 class="font-lg font-bold my-6 text-center">
                In-Process Example Results with Single-Base Propellant
              </h2>

              <a
                :href="
                  require('../../assets/IPSeries/CriticalHeightVariations-min.png')
                "
                target="_blank"
              >
                <img
                  style="width:75%;"
                  class="m-auto"
                  src="@/assets/IPSeries/CriticalHeightVariations-min.png"
                />
              </a>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <div>
                <table class="test-table">
                  <tbody>
                    <tr>
                      <th>Key Parameter</th>
                      <th>Objectives</th>
                      <th>Origin</th>
                      <th>Specs</th>
                    </tr>
                    <tr>
                      <td>Confining medium</td>
                      <td>
                        Observe an explosion or onset of explosion evidenced by
                        the steel pipe and/or end cap (bulging, splitting,
                        fragmenting, etc.)
                      </td>
                      <td></td>
                      <td>
                        <ul class="list-disc ml-4">
                          <li>
                            Schedule 40 carbon steel (A53 Grade B) pipe of
                            various diameters and varying lengths
                          </li>
                          <li>3000-lb end cap on bottom</li>
                          <li>
                            Unrestricted venting: open top with free space above
                            fill height not more than 3 inches from the top
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Ignition stimulus</td>
                      <td>
                        Ensure a sufficient amount of the material is ignited by
                        flame at the bottom of the pipe
                      </td>
                      <td></td>
                      <td>
                        <ul class="list-disc ml-4">
                          <li>
                            12 gram FFFg black powder bag igniter or equivalent
                            for pipe diameters greater than 1 inch
                          </li>
                          <li>
                            6 to 12-gram rod igniters for pipe diameters 1 inch
                            or less
                          </li>
                          <li>
                            6-gram rod igniter for pipe diameters less than 0.5
                            inches
                          </li>
                          <li>
                            Electric match or pyrogen igniter only as
                            appropriate
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td>Bottom ignition</td>
                      <td>
                        Allows the substance’s own weight to provide
                        self-confinement
                      </td>
                      <td></td>
                      <td>Ignition at the bottom of the pipe</td>
                    </tr>
                    <tr>
                      <td>Fill height and diameter of the substance</td>
                      <td>
                        Determine the fill height at which a substance at a
                        given diameter transitions from deflagration to
                        explosion; the relationship between fill height and
                        diameter can be determined by testing different
                        diameters
                      </td>
                      <td></td>
                      <td>
                        Generally, 1, 2, and 3-inch diameter pipes are tested in
                        lengths ranging from 3 to 24 inches in 3-inch increments
                        (max)
                      </td>
                    </tr>
                    <tr>
                      <td>Substance density</td>
                      <td>
                        Determine whether the substance in its in-process form
                        is sensitive to the test stimulus
                      </td>
                      <td></td>
                      <td>
                        Substance to be tested as near as possible to the
                        in-process density
                      </td>
                    </tr>
                    <tr>
                      <td>Number of trials</td>
                      <td>
                        Sufficient to give a reasonable level of confidence in
                        the reproducibility of the test results
                      </td>
                      <td></td>
                      <td>3 trials</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Damage to the steel pipe or end cap</td>
                    <td>Visual post-test inspection</td>
                    <td>
                      Bulging, splitting, or fragmentation of the pipe or end
                      cap are considered a positive result (i.e. failure).
                      In-process HD 1.1 for substances above the height given
                      the in-process diameter, else in-process HD 1.3 candidate
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="No Damage to Pipe (-) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166131380?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166131380"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
          </div>
          <div class="w-full md:w-1/2 p-4">
            <TitleComponent
              class="pt-8"
              title="Explosion (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/166226617?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo166226617"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import SideBar from "@/components/IPSeriesSideBar";

export default {
  components: {
    TitleComponent,
    SideBar
  }
};
</script>

<style lang="scss"></style>
